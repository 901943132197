import React from "react";
import "./Contact.css";
import contactUs_img from "../assets/images/ContactUs_Images/contactUs-img.png";
import ContactUsForm from "./contact-form/ContactUs_Form";
import useMediaQuery from "use-mediaquery";
import { AiOutlineClose } from "react-icons/ai";
import avatar_img from './avatar.png'

const ContactUs = ({ setShowBanner1Popup, popup }) => {
  const showForm = useMediaQuery("(max-width: 650px)");

  return (
    <div id="contactUs">
      <AiOutlineClose
        onClick={() => setShowBanner1Popup(false)}
        className="contactPopupCloseIcon"
        style={{ display: "none" }}
      />
      <section className="contact-container">
        <div className="contact-left-container">
          <h2>Let's Talk</h2>

          {!popup || !showForm ? (
            <p>
              Accelerate your Digital Transformation Journey with 3E Digital
              Solutions. We provide the exceptional service we would want to
              experience ourselves! Fill out the form below and a member of our
              team will get in touch with you.
            </p>
          ) : (
            <p>24*7 available for you</p>
          )}

          {!showForm && (
            <ContactUsForm setShowBanner1Popup={setShowBanner1Popup} />
          )}
        </div>

        <div className="contact-right-container">
          <div className="image-container">
            <div>
              <img src={avatar_img} alt="pic" />
            </div>
            <div className="contact-designation" style={{width:"100%"}}>
              Your Digital Partner
              {/* <br /> Co-Founder & CTO */}
            </div>
          </div>

          <div className="contactRight-listItems">
            <span>
              <ul>
                <li>NDA signed and honoured</li>
                <li>Free Consultation & Quote</li>
                <li>Client Satisfaction Confirmed</li>
              </ul>
            </span>

            <span>
              <ul>
                <li>Lowest Price Promised</li>
                <li>Scalable & High Performing Apps</li>
                <li>Free 2 month's support post delivery</li>
              </ul>
            </span>
          </div>
        </div>

        {showForm && (
          <ContactUsForm setShowBanner1Popup={setShowBanner1Popup} />
        )}
      </section>
    </div>
  );
};

export default ContactUs;
