import React, { useState } from "react";
import contact from "../../Firebase/Client";

const ContactUsForm = ({ setShowBanner1Popup }) => {
  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setuserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

  
    setuserData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });

    setShowBanner1Popup(false);
    contact.post("/contactUs.json", userData);
  };

  return (
    <div className="contactLeft-form">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div>
          <span>
            <input
              type="text"
              className="inputForm-control"
              placeholder="First Name"
              maxLength={15}
              name="firstName"
              value={userData.firstName}
              onChange={handleOnChange}
              required
            />
          </span>
          <span>
            <input
              type="text"
              className="inputForm-control"
              placeholder="Last Name"
              maxLength={15}
              name="lastName"
              value={userData.lastName}
              onChange={handleOnChange}
            />
          </span>
        </div>
        <div>
          <input
            type="email"
            className="inputForm-control"
            placeholder="Email"
            maxLength={40}
            name="email"
            value={userData.email}
            onChange={handleOnChange}
            required
          />
          <input
            type="number"
            className="inputForm-control"
            placeholder="Phone Number"
            maxLength={10}
            onInput={maxLengthCheck}
            name="phone"
            value={userData.phone}
            onChange={handleOnChange}
            required
          />
        </div>
        <div>
          <textarea
            className="inputForm-control inputForm-textarea"
            placeholder="Message"
            name="message"
            value={userData.message}
            onChange={handleOnChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUsForm;
