import React, { useState, useEffect } from "react";
import './Banner.css'
import { Carousel } from 'react-responsive-carousel'
import SlideBanner from './BannerSlide/SlideBanner'
import bannerImg1 from "../assets/images/banner_images_new/banner1.png";
import bannerImg1Mobile from "../assets/images/banner_images_new/banner1Mobile.png";
import bannerImg2 from "../assets/images/banner_images_new/banner2.png";
import bannerImg2Mobile from "../assets/images/banner_images_new/banner2Mobile.png";
import bannerImg3 from "../assets/images/banner_images_new/banner3.png";
import bannerImg3Mobile from "../assets/images/banner_images_new/banner3Mobile.png";
import bannerImg4 from "../assets/images/banner_images_new/banner4.png";
import bannerImg4Mobile from "../assets/images/banner_images_new/banner4Mobile.png";
import bannerImg4_1 from "../assets/images/banner_images_new/banner4-1.png";
import bannerImg4_1Mobile from "../assets/images/banner_images_new/banner4-1Mobile.png";
import useMediaQuery from "use-mediaquery";


const Banner = ({ showBanner1Popup, setShowBanner1Popup }) => {

    const isMobile = useMediaQuery("(max-width: 768px)");
    const [isTrimTxt, setIsTrimTxt] = useState(false);

    useEffect(() => {
        if (isMobile) {
            setIsTrimTxt(true);
        } else {
            setIsTrimTxt(false);
        }
    }, [isMobile])


    const banner3Colors = {
        c1: "#125a6b",
        c2: "#43adc4",
        c3: "gray"
    }

    if (showBanner1Popup) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }

    const handleReadMore = () => {
        setIsTrimTxt(!isTrimTxt);
    }

    // const [showBanner1Popup, setShowBanner1Popup] = useState(false);

    return (
        <Carousel
            showArrows={true}//
            stopOnHover={false}
            showIndicators={true}
            infiniteLoop={true}
            autoPlay={true}//
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            dynamicHeight={false}
            renderArrowPrev={(click) => {
                return (
                    <button
                        onClick={click}
                        class="arrow-btn-left">
                        <img
                        style={{rotate:'180deg'}}
                         src="https://www.freeiconspng.com/thumbs/arrow-icon/right-arrow-icon-27.png" />
                    </button>
                )
            }}
            renderArrowNext={(click) => {
                return (
                    <button
                        onClick={click}
                        class="arrow-btn-right">
                        <img src="https://www.freeiconspng.com/thumbs/arrow-icon/right-arrow-icon-27.png" />
                    </button>
                )
            }}
            
        >
            <SlideBanner
                title={<span>Tired of chasing freelancers for work?</span>}
                bannerClassName="bannerPage1"
                content1={<span>Don't worry we are here to help you out. We are not <span style={{ textDecoration: "line-through" }}>freelancers</span> but Your Digital Partner.</span>}
                bannerImgSrc={!isMobile ? bannerImg1 : bannerImg1Mobile}
                buttonText="Know More"
                setShowBanner1Popup={setShowBanner1Popup}
            />

            <SlideBanner
                title={<span>Your Technology Partner</span>}
                bannerClassName="bannerPage2"
                content1={<span style={{ fontWeight: "500" }}>Hire 3E professionals in the budget of freelancers.</span>}
                content2={<><li>Meticolous planning</li><li>Seamless Execution</li><li>Bug free code</li><li>Guarenteed Success</li></>}
                bannerImgSrc={!isMobile ? bannerImg2 : bannerImg2Mobile}
                buttonText="Let's Talk"
                aHref="/#contactUs"
            />

            <SlideBanner
                title={<span>What our client says?</span>}
                bannerClassName="bannerPage3"
                content1={<span>Enhancing an existing product and making the experience better for users requires Meticulous Planning and Seamless execution.</span>}
                content2={<span>Myself and Vijay Saini started working with <span style={{ color: banner3Colors.c1 }}>Monika Bindal</span> and <span style={{ color: banner3Colors.c2 }}>3E Digital Solutions</span> 6 months back and they are true tech partners for growth.</span>}
                content3={!isTrimTxt ? <span>From Implementing Short Videos on both Android and iOS to taking ownership in improving crashes/errors to giving awesome/executable ideas as a partner, it's been an amazing journey till now.</span>
                    : <span>From Implementing Short Videos on both Android and iOS to taking owne<span onClick={handleReadMore} style={{ color: banner3Colors.c3, cursor: "pointer" }}>...read more</span></span>}
                content4={!isTrimTxt && <span>Thank you so much for your partnership <span style={{ color: banner3Colors.c2 }}>Monika</span> and Team <span style={{ color: banner3Colors.c2 }}>3E Digital Solutions</span>.</span>}
                bannerImgSrc={!isMobile ? bannerImg3 : bannerImg3Mobile}
                buttonText="See More Testimonials"
                aHref="/#testimonial"
            />

            <SlideBanner
                title={<span>Projects we have worked on</span>}
                bannerClassName="bannerPage4"
                content1={<span>Every solution has its own story.</span>}
                content2={<span>We follow agile methodology to develop your solution budget friendly, fast with high quality.</span>}
                content3={<span>Sounds exciting! Check out our case studies to know more about our work.</span>}
                bannerImgSrc={!isMobile ? bannerImg4 : bannerImg4Mobile}
                bannerImgSrc_Inner={!isMobile ? bannerImg4_1 : bannerImg4_1Mobile}
                buttonText="Go to Case Studies"
                aHref="/#casestudy"
            />
        </Carousel>
    );
}

export default Banner


