import React from 'react'
import './casestudysingle.css'

function CaseStudySingle() {
    return (
        <section className="casestudy__wrapper">
            CaseStdySingle
        </section>
    )
}

export default CaseStudySingle
