import React, { useEffect, useRef } from "react";
import ContactUs from "../ContactUs/ContactUs";
import "./ContactPopup.css";

const ContactPopup = ({ setShowBanner1Popup }) => {
  let refClose = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!refClose?.current?.contains(event?.target)) {
        setShowBanner1Popup(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div className="contactPopupBackground"></div>

      <div ref={refClose} className="contactUsPopupContainer">
        <ContactUs setShowBanner1Popup={setShowBanner1Popup} popup={true} />
      </div>
    </>
  );
};

export default ContactPopup;
