import travelBuddy from "../assets/images/casestudy/travelBuddy.png";
import ngoBuddy from "../assets/images/casestudy/ngoBuddy.png";
import l2l from "../assets/images/casestudy/l2l.png";
import carns from "../assets/images/casestudy/carns.png";
import arumhome from "../assets/images/casestudy/arumhome.png";
import clothing from "../assets/images/casestudy/clothing.png";
import nearme from "../assets/images/casestudy/nearme.png";
import study from "../assets/images/casestudy/study.png";
import soaowa from "../assets/images/casestudy/soaowa.png";
import startup from "../assets/images/casestudy/startup.png";
import mnm from "../assets/images/casestudy/m&m.png";
import abhijeet from "../assets/images/casestudy/abhijeet.png";

import travelBuddyLogo from "../assets/images/casestudy/logo/travelBuddy.png";
import ngoBuddyLogo from "../assets/images/casestudy/logo/ngoBuddy.png";
import l2lLogo from "../assets/images/casestudy/logo/l2l.png";
import carnsLogo from "../assets/images/casestudy/logo/carns.png";
import arumhomeLogo from "../assets/images/casestudy/logo/arumhome.png";
import clothingLogo from "../assets/images/casestudy/logo/clothing.png";
import nearmeLogo from "../assets/images/casestudy/logo/nearme.png";
import studyLogo from "../assets/images/casestudy/logo/study.png";
import soaowaLogo from "../assets/images/casestudy/logo/soaowa.png";
import startupLogo from "../assets/images/casestudy/logo/startup.png";
import mnmLogo from "../assets/images/casestudy/logo/m&m.png";
import abhijeetLogo from "../assets/images/casestudy/logo/abhijeet.png";

import travelBuddyMobile from "../assets/images/casestudy/mobile_icons/travelBuddy.png";
import ngoBuddyMobile from "../assets/images/casestudy/mobile_icons/ngoBuddy.png";
import l2lMobile from "../assets/images/casestudy/mobile_icons/l2l.png";
import carnsMobile from "../assets/images/casestudy/mobile_icons/carns.png";
import arumhomeMobile from "../assets/images/casestudy/mobile_icons/arumhome.png";
import clothingMobile from "../assets/images/casestudy/mobile_icons/clothing.png";
import nearmeMobile from "../assets/images/casestudy/mobile_icons/nearme.png";
import studyMobile from "../assets/images/casestudy/mobile_icons/study.png";
import soaowaMobile from "../assets/images/casestudy/mobile_icons/soaowa.png";
import startupMobile from "../assets/images/casestudy/mobile_icons/startup.png";
import mnmMobile from "../assets/images/casestudy/mobile_icons/m&m.png";
import abhijeetMobile from "../assets/images/casestudy/mobile_icons/abhijeet.png";

import icon1 from "../assets/images/casestudy/tech_icons/android.png";
import icon2 from "../assets/images/casestudy/tech_icons/apple.png";
import icon3 from "../assets/images/casestudy/tech_icons/pc.png";

export const data = [
  {
    id: 1,
    image: travelBuddy,
    title: "Travel Buddy",
    tagline:
      "Social Travel App. \n Best travel partner finder & travel planner app",
    logo: travelBuddyLogo,
    maxLogoWidth: "40px",
    mobile_icon: travelBuddyMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 2,
    image: ngoBuddy,
    title: "NGO Buddy",
    tagline:
      "India's #1 Fully Functional decentralized \n social platform to support NGOs",
    logo: ngoBuddyLogo,
    maxLogoWidth: "37px",
    mobile_icon: ngoBuddyMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 3,
    image: l2l,
    title: "Lyrics 2 Literature",
    tagline: "Learning Management System & \n E-Commerce Solution",
    logo: l2lLogo,
    maxLogoWidth: "56px",
    mobile_icon: l2lMobile,
    tech_icons: [icon1, icon2, icon3],
  },
  {
    id: 4,
    image: carns,
    title: "Carns Realtors",
    tagline:
      "Real Estate Solution \n Website for owner and mobile app for tenants",
    logo: carnsLogo,
    maxLogoWidth: "29px",
    mobile_icon: carnsMobile,
    tech_icons: [icon1, icon2, icon3],
  },
  {
    id: 5,
    image: arumhome,
    title: "Arum Home",
    tagline:
      "Old Age Home Solution \n Website for management \n Mobile app for staff and guardians",
    logo: arumhomeLogo,
    maxLogoWidth: "32px",
    mobile_icon: arumhomeMobile,
    tech_icons: [icon1, icon2, icon3],
  },
  {
    id: 6,
    image: clothing,
    title: "Neomelo Clothing store",
    tagline: "Complete E-Commerce Solution",
    logo: clothingLogo,
    maxLogoWidth: "36px",
    mobile_icon: clothingMobile,
    tech_icons: [icon1, icon2, icon3],
  },
  {
    id: 7,
    image: nearme,
    title: "You Near Me",
    tagline: "Location Tracking Solution",
    logo: nearmeLogo,
    maxLogoWidth: "49px",
    mobile_icon: nearmeMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 8,
    image: study,
    title: "Study Corner",
    tagline: "EdTech Solution for College Students",
    logo: studyLogo,
    maxLogoWidth: "33px",
    mobile_icon: studyMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 9,
    image: soaowa,
    title: "SOAOWA",
    tagline: "Apartment Management Solution",
    logo: soaowaLogo,
    maxLogoWidth: "28px",
    mobile_icon: soaowaMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 10,
    image: startup,
    title: "Indian Startups",
    tagline: "Social Networking & Business Platform \n (For entrepreneurs)",
    logo: startupLogo,
    maxLogoWidth: "38px",
    mobile_icon: startupMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 11,
    image: mnm,
    title: "M&M Biryani",
    tagline: "Food Ordering E-Solution",
    logo: mnmLogo,
    maxLogoWidth: "53px",
    mobile_icon: mnmMobile,
    tech_icons: [icon1, icon2],
  },
  {
    id: 12,
    image: abhijeet,
    title: "Abhijeet Soni",
    tagline: "Legal Grievance Information Solution",
    logo: abhijeetLogo,
    maxLogoWidth: "49px",
    mobile_icon: abhijeetMobile,
    tech_icons: [icon3],
  },
];
