import React from "react";
import { FaAndroid, FaApple, FaLaptop, FaChevronRight } from "react-icons/fa";
import ContactPopup from "../../ContactUsPopupModal/ContactPopup";


const SlideBanner = ({
  title,
  content1,
  bannerImgSrc,
  bannerImgSrc_Inner,
  buttonText,
  content2,
  content3,
  content4,
  bannerClassName,
  setShowBanner1Popup,
  aHref,
}) => {
  return (
    <>
      <section className="banner-container">
        <div className={`banner-content ${bannerClassName}`}>
          <div className="left-container">
            <div className="main-heading">{title}</div>
            <p>{content1}</p>
            {content2 &&
              (bannerClassName === "bannerPage2" ? (
                <ul>{content2}</ul>
              ) : (
                <p>{content2}</p>
              ))}
            {content3 && <p>{content3}</p>}
            {content4 && <p>{content4}</p>}
            <a href={aHref}>
              {buttonText && (
                <button
                  onClick={() => {
                    bannerClassName === "bannerPage1" &&
                      setShowBanner1Popup(true);
                  }}
                >
                  {buttonText}
                </button>
              )}
            </a>
          </div>

          <div className="right-container">
            <div className="right-container-content">
              <img src={bannerImgSrc} alt="banner" />
              {bannerImgSrc_Inner && (
                <img
                  src={bannerImgSrc_Inner}
                  className="rightContainer-InnerImg"
                  alt="banner"
                />
              )}
              {bannerClassName === "bannerPage3" && (
                <div className="designation-container">
                  <div className="desigName">Saurav Chakraborty</div>
                  <div className="desigPara">Founder & CEO - Travel Buddy</div>
                </div>
              )}

              {bannerClassName === "bannerPage2" && (
                <div className="designation-container">
                  <span className="techStacks">
                    <FaAndroid
                      className="techStackIcons"
                      style={{ color: "green" }}
                    />
                    Android
                  </span>
                  <span className="techStacks">
                    <FaApple
                      className="techStackIcons"
                      style={{ color: "#333" }}
                    />
                    iOS
                  </span>
                  <span className="techStacks">
                    <FaLaptop
                      className="techStackIcons"
                      style={{ color: "#29aaf9" }}
                    />
                    Web
                  </span>
                  <a href="/#technologies">
                    <span className="techStacks moreTechStack">
                      <span>More Tech Stack</span>
                      <span>
                        <FaChevronRight className="techStackIcons" />
                      </span>
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SlideBanner;
