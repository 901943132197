import React from 'react'
import './notfound.css'

function NotFound() {
    return (
        <section className="page__not__found__container">
            404 Page Not Found
        </section>
    )
}

export default NotFound
